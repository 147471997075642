import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CountUp from "react-countup";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import ToastService from "../../../components/Common/toastService.js";
import ValueFormat from "../../../util/ValueFormat.js";
import filterClockIcon from "../../../assets/images/common/filterClockIcon.svg";
import filterClockIconDe from "../../../assets/images/common/filterIconClockDeactive.svg";
import {  Fab } from "@mui/material";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";
import UserAdminDashboardSubMenu from "./UserAdminDashboardSubMenu.js";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import DriverBehaviourRatingForUser from "./DriverBehaviorRating.js";
import MarketOverview from "../MarketOverview.js";
import CardImg1 from "../../../assets/images/t_animal_crossing.png";
import Caution from "../../../assets/images/t_caution.png";
import Curve from "../../../assets/images/t_curves.png";
import Round from "../../../assets/images/Round-about.svg";
import Hillimg from "../../../assets/images/t_hill.png";
import HillDownWards from "../../../assets/images/t_hill_downwards.png";
import hillUp from "../../../assets/images/t_hill_upwards.png";
import icyConditions from "../../../assets/images/t_icy_conditions.png";
import Intersection from "../../../assets/images/t_intersection.png";
import LaneMerge from "../../../assets/images/t_lane_merge.png";
import LowGearArea from "../../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../../assets/images/t_no_overtaking.png";
import PedestrianCrossing from "../../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../../assets/images/t_priority.png";
import RailwayCrossing from "../../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../../assets/images/t_risk_of_grouping.png";
import School from "../../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../../assets/images/t_slippery_road.png";
import Stopimg from "../../../assets/images/t_stop.png";
import TrafficLight from "../../../assets/images/t_traffic_light.png";
import WindImg from "../../../assets/images/t_wind.png";
import WindingRoad from "../../../assets/images/t_winding_road.png";
import YieldImg from "../../../assets/images/t_yield.png";
import DownloadIcon from "../../../assets/images/adminDashboard/DownloadIconNew.svg";
import "./SectionOne.css";
import downArroeIcon from "../../../assets/images/common/downArrowImage.svg";
import upArroeIcon from "../../../assets/images/common/upArrowImage.svg";
import upArrowIconRed from "../../../assets/images/common/upArrowRed.svg";
import downArrowIconNew from "../../../assets/images/common/downGreen.svg";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExportScreenDownloadPdf from "../../../components/ExportScreenPdf/exportScreenDownloadPdf.js";
import UserDashboardCtrlr from "../../../controller/UserDashboardCtrlr.js";

const data = [
  {
    type: "ANIMAL_CROSSING",
    title: "Animal Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: CardImg1,
  },
  {
    type: "CAUTION",
    title: "Caution",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Caution,
  },
  {
    type: "CURVE",
    title: "Curve",
    overall: 0,
    currentMonthGraphData: [0],
    image: Curve,
  },
  
  {
    type: "HILL",
    title: "Hill",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Hillimg,
  },
  {
    type: "HILL_DOWN",
    title: "Hill Downwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: HillDownWards,
  },
  {
    type: "HILL_UP",
    title: "Hill Upwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: hillUp,
  },
  {
    type: "ICY",
    title: "Icy Conditions",
    overallData: 0,
    currentMonthGraphData: [0],
    image: icyConditions,
  },
  {
    type: "INTERSECTION",
    title: "Intersection",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Intersection,
  },
  {
    type: "LANE_MERGE",
    title: "Lane Merge",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LaneMerge,
  },
  {
    type: "LOW_GEAR",
    title: "Low Gear Area",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LowGearArea,
  },

  {
    type: "NARROW_ROAD",
    title: "Narrow Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NarrowRoad,
  },
  {
    type: "OVERTAKING",
    title: "No Overtaking",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NoOverTaking,
  },

  {
    type: "CROSSING",
    title: "Pedestrian Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: PedestrianCrossing,
  },
  {
    type: "PRIORITY",
    title: "Priority",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Priority,
  },
  {
    type: "RAILWAY",
    title: "Railway Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RailwayCrossing,
  },
  {
    type: "RISK_GROUNDING",
    title: "Risk Of Grounding",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RiskOfGrounding,
  },
  {
    type: "ROUND",
    title: "Round About",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Round,
  },
  {
    type: "SCHOOL",
    title: "School",
    overallData: 0,
    currentMonthGraphData: [0],
    image: School,
  },
  {
    type: "SLIPPERY_ROADS",
    title: "Slippery Roads",
    overallData: 0,
    currentMonthGraphData: [0],
    image: SlipperyRoads,
  },
  {
    type: "STOP",
    title: "Stop",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Stopimg,
  },

  {
    type: "TRAFFIC_LIGHT",
    title: "Traffic Light",
    overallData: 0,
    currentMonthGraphData: [0],
    image: TrafficLight,
  },
  {
    type: "WIND",
    title: "Wind",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindImg,
  },
  {
    type: "WINDING_ROAD",
    title: "Winding Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindingRoad,
  },
  {
    type: "YIELD",
    title: "Yield",
    overallData: 0,
    currentMonthGraphData: [0],
    image: YieldImg,
  },
];

const UserDashboard = () => {
  
  let defaultValueList = [
    {
      periodSummaryDrivingScore: " ",
      period1ResultCalculatedDrivingScore: 0,     
      period1ResultTravelDuration: "0.00",
      period1ResultTravelDistance: "0.00",
      period1ResultTotalRides: "0",
      period2ResultCalculatedDrivingScore: 0,     
      period2ResultTravelDuration: "0.00",
      period2ResultTravelDistance: "0.00",
      period2ResultTotalRides: "0",
      periodSummarySpeedingViolation: 0,     
      periodSummaryMobileViolation: 0,   
      accBrakCorViolation: 0, 
      periodSummaryDrivingRedAlert: 0, 
      alertImagesPeriodOne: " ",
      alertImagesPeriodTwo: " ",     
      badRidesCountSummary: 0,
      // textDataValue: "",
    },
  ];
 
  const [overallDataProps, setOverallDataProps] = useState([]);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");   
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);  
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  }); 
  const [totalDays, setTotalDays] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [summaryText, setSummaryText] = useState("");

  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);
  

  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const [riskDataList, setRiskDataList] = useState(
    splitArrayIntoChunks(data) || []
  );
  
  
  const [drivingDataList, setDrivingDataList] = useState(defaultValueList);  

  const [periodOneValue, setPeriodOneValue] = useState(
    dayjs(new Date()).subtract(2, "month")
  ); //dayjs().subtract(1, "month").format("YYYY-MM")
  const [periodTwoValue, setPeriodTwoValue] = useState(
    dayjs(new Date()).subtract(1, "month")
  );

  const lastMonthEnd = dayjs().subtract(1, "month").endOf("month");

  const lastMonthEndPeriodOne = dayjs().subtract(2, "month").endOf("month");
 
 
  
  const shouldDisableDate = (date) => {
    if (!periodTwoValue) return false;

    // Disable all dates in the month of periodOneValue
    return date.month() === periodTwoValue.month() && date.year() === periodTwoValue.year();
  };

  
  // 1
  const getDrivingComparisionData = async () => {

    try {

      let checkTodayMonth = dayjs(new Date());

      if (
        periodOneValue.isBefore(periodTwoValue) &&
        periodOneValue.format("YYYY-MM") !== periodTwoValue.format("YYYY-MM") &&
        periodOneValue.format("YYYY-MM") !== checkTodayMonth.format("YYYY-MM") &&
        periodTwoValue.format("YYYY-MM") !== checkTodayMonth.format("YYYY-MM")
      ) {

        let period1Date = periodOneValue
          ? periodOneValue.format("YYYY-MM") + "-01 00:00:00"
          : "";
        let period2Date = periodTwoValue
          ? periodTwoValue.format("YYYY-MM") + "-01 00:00:00"
          : "";
       
        var result =
          await UserDashboardCtrlr.getV1UserPeriodicalComparisonSummary(
            period1Date,
            period2Date
          );
          console.log(result,"resultresult")
        
        var resultJSON = JSON.parse(result);     
        console.log(resultJSON,"resultJSONUser")  
        var status = resultJSON.status;       
        
        if (status == "SUCCESS") {

          
          let resultJSONData = resultJSON?.data;         
          var updatedDrivingDataList = [...drivingDataList];

          resultJSONData.map((apiResultData) => {
           
            var getPeriodSummary = apiResultData?.periodSummary;

            var periodSummaryDrivingScore = parseFloat(
              getPeriodSummary?.calculatedDrivingScore ?? 0
            ).toFixed(0);
            if (isNaN(periodSummaryDrivingScore)) {
              periodSummaryDrivingScore = 0; // Use 0 only if the value is not valid
            }

            var getPeriod1Result = apiResultData?.period1Summary;       

            var period1ResultCalculatedDrivingScore = parseFloat(
              ValueFormat.parseAsFloat(
                getPeriod1Result?.calculatedDrivingScore,
                0
              )
            );            

            var period1ResultTravelDuration = parseFloat(
              ValueFormat.parseAsFloat(
                getPeriod1Result?.totlaDurationInMin,
                0
              )
            );
            
            var period1ResultTravelDistance = parseFloat(
              ValueFormat.parseAsFloat(
                getPeriod1Result?.totalDistanceInKm,
                0
              )
            );

            var period1ResultTotalRides = parseInt(
              ValueFormat.parseAsInt(getPeriod1Result?.totalRideCount)
            );

            // period2Result
            
           var getPeriod2Result = apiResultData?.period2Summary;

            var period2ResultCalculatedDrivingScore = parseFloat(
              ValueFormat.parseAsFloat(
                getPeriod2Result?.calculatedDrivingScore,
                0
              )
            );            

            var period2ResultTravelDuration = ValueFormat.parseAsFloat(getPeriod2Result?.totlaDurationInMin,0);

            var period2ResultTravelDistance = parseFloat(
              ValueFormat.parseAsFloat(
                getPeriod2Result?.totalDistanceInKm,
                0
              )
            );

            var period2ResultTotalRides = parseInt(
              ValueFormat.parseAsInt(getPeriod2Result?.totalRideCount)
            );

            // Speeding Violation

            var periodSummarySpeedingViolation = parseFloat(
              getPeriodSummary?.speedingViolationScore ?? 0
            ).toFixed(0);
            if (isNaN(periodSummarySpeedingViolation)) {
              periodSummarySpeedingViolation = 0; // Use 0 only if the value is not valid
            }

            //  Mobile Violation

            var periodSummaryMobileViolation = parseFloat(
              getPeriodSummary?.mobileViolationScore ?? 0
            ).toFixed(0);
            if (isNaN(periodSummaryMobileViolation)) {
              periodSummaryMobileViolation = 0; // Use 0 only if the value is not valid
            }          

            //  Acc/Brak/Cor Violation        
            
            var accBrakCorViolation = parseFloat(
              getPeriodSummary?.accBrakCorViolationScore ?? 0
            ).toFixed(0);
            if (isNaN(accBrakCorViolation)) {
              accBrakCorViolation = 0; // Use 0 only if the value is not valid
            }              
           
            // Driving Red Alert

            var periodSummaryDrivingRedAlert = parseFloat(
              getPeriodSummary?.highAlertViolationScore ?? 0
            ).toFixed(0);
            if (isNaN(periodSummaryDrivingRedAlert)) {
              periodSummaryDrivingRedAlert = 0; 
            }         

            var period1ResultHighTopAlertName = ValueFormat.parseAsString(
              getPeriod1Result?.highAlertTopCode
            );
           
            var period2ResultHighTopAlertName = ValueFormat.parseAsString(
              getPeriod2Result?.highAlertTopCode
            );

            var badRidesCountSummary = parseFloat(
              getPeriodSummary?.badRideViolationScore ?? 0
            ).toFixed(0);
            if (isNaN(badRidesCountSummary)) {
              badRidesCountSummary = 0; 
            }           

            let alertImagesPeriod1 = " ";

            if (period1ResultHighTopAlertName == "ANIMAL_CROSSING") {
              alertImagesPeriod1 = CardImg1;
            } else if (period1ResultHighTopAlertName == "CAUTION") {
              alertImagesPeriod1 = Caution;
            } else if (period1ResultHighTopAlertName == "CURVE") {
              alertImagesPeriod1 = Curve;
            } else if (period1ResultHighTopAlertName == "ROUND_ABOUT") {
              alertImagesPeriod1 = Round;
            } else if (period1ResultHighTopAlertName == "HILL") {
              alertImagesPeriod1 = Hillimg;
            } else if (period1ResultHighTopAlertName == "HILL_DOWNWARDS") {
              alertImagesPeriod1 = HillDownWards;
            } else if (period1ResultHighTopAlertName == "HILL_UPWARDS") {
              alertImagesPeriod1 = hillUp;
            } else if (period1ResultHighTopAlertName == "ICY_CONDITIONS") {
              alertImagesPeriod1 = icyConditions;
            } else if (period1ResultHighTopAlertName == "INTERSECTION") {
              alertImagesPeriod1 = Intersection;
            } else if (period1ResultHighTopAlertName == "LANE_MERGE") {
              alertImagesPeriod1 = LaneMerge;
            } else if (period1ResultHighTopAlertName == "LOW_GEAR_AREA") {
              alertImagesPeriod1 = LowGearArea;
            } else if (period1ResultHighTopAlertName == "NARROW_ROAD") {
              alertImagesPeriod1 = NarrowRoad;
            } else if (period1ResultHighTopAlertName == "NO_OVERTAKING") {
              alertImagesPeriod1 = NoOverTaking;
            } else if (period1ResultHighTopAlertName == "PEDESTRIAN_CROSSING") {
              alertImagesPeriod1 = PedestrianCrossing;
            } else if (period1ResultHighTopAlertName == "PRIORITY") {
              alertImagesPeriod1 = Priority;
            } else if (period1ResultHighTopAlertName == "RAILWAY_CROSSING") {
              alertImagesPeriod1 = RailwayCrossing;
            } else if (period1ResultHighTopAlertName == "RISK_OF_GROUNDING") {
              alertImagesPeriod1 = RiskOfGrounding;
            } else if(period1ResultHighTopAlertName == "SCHOOL_ZONE"){
              alertImagesPeriod1 = School;
            }else if (period1ResultHighTopAlertName == "SLIPPERY_ROADS") {
              alertImagesPeriod1 = SlipperyRoads;
            } else if (period1ResultHighTopAlertName == "STOP_SIGN") {
              alertImagesPeriod1 = Stopimg;
            } else if (period1ResultHighTopAlertName == "TRAFFIC_LIGHT") {
              alertImagesPeriod1 = TrafficLight;
            } else if (period1ResultHighTopAlertName == "WIND") {
              alertImagesPeriod1 = WIND;
            } else if (period1ResultHighTopAlertName == "WINDING_ROAD") {
              alertImagesPeriod1 = WindingRoad;
            } else if (period1ResultHighTopAlertName == "YIELD") {
              alertImagesPeriod1 = YieldImg;
            }

            let alertImagesPeriod2 = " ";

            if (period2ResultHighTopAlertName == "ANIMAL_CROSSING") {
              alertImagesPeriod2 = CardImg1;
            } else if (period2ResultHighTopAlertName == "CAUTION") {
              alertImagesPeriod2 = Caution;
            } else if (period2ResultHighTopAlertName == "CURVE") {
              alertImagesPeriod2 = Curve;
            } else if (period2ResultHighTopAlertName == "ROUND_ABOUT") {
              alertImagesPeriod2 = Round;
            } else if (period2ResultHighTopAlertName == "HILL") {
              alertImagesPeriod2 = Hillimg;
            } else if (period2ResultHighTopAlertName == "HILL_DOWNWARDS") {
              alertImagesPeriod2 = HillDownWards;
            } else if (period2ResultHighTopAlertName == "HILL_UPWARDS") {
              alertImagesPeriod2 = hillUp;
            } else if (period2ResultHighTopAlertName == "ICY_CONDITIONS") {
              alertImagesPeriod2 = icyConditions;
            }else if (period2ResultHighTopAlertName == "INTERSECTION") {
              alertImagesPeriod2 = Intersection;
            } else if (period2ResultHighTopAlertName == "LANE_MERGE") {
              alertImagesPeriod2 = LaneMerge;
            } else if (period2ResultHighTopAlertName == "LOW_GEAR_AREA") {
              alertImagesPeriod2 = LowGearArea;
            } else if (period2ResultHighTopAlertName == "NARROW_ROAD") {
              alertImagesPeriod2 = NarrowRoad;
            } else if (period2ResultHighTopAlertName == "NO_OVERTAKING") {
              alertImagesPeriod2 = NoOverTaking;
            } else if (period2ResultHighTopAlertName == "PEDESTRIAN_CROSSING") {
              alertImagesPeriod2 = PedestrianCrossing;
            } else if (period2ResultHighTopAlertName == "PRIORITY") {
              alertImagesPeriod2 = Priority;
            } else if (period2ResultHighTopAlertName == "RAILWAY_CROSSING") {
              alertImagesPeriod2 = RailwayCrossing;
            } else if (period2ResultHighTopAlertName == "RISK_OF_GROUNDING") {
              alertImagesPeriod2 = RiskOfGrounding;
            } else if(period2ResultHighTopAlertName == "SCHOOL_ZONE"){
              alertImagesPeriod2 = School;
            }else if (period2ResultHighTopAlertName == "SLIPPERY_ROADS") {
              alertImagesPeriod2 = SlipperyRoads;
            } else if (period2ResultHighTopAlertName == "STOP_SIGN") {
              alertImagesPeriod2 = Stopimg;
            } else if (period2ResultHighTopAlertName == "TRAFFIC_LIGHT") {
              alertImagesPeriod2 = TrafficLight;
            } else if (period2ResultHighTopAlertName == "WIND") {
              alertImagesPeriod2 = WIND;
            } else if (period2ResultHighTopAlertName == "WINDING_ROAD") {
              alertImagesPeriod2 = WindingRoad;
            } else if (period2ResultHighTopAlertName == "YIELD") {
              alertImagesPeriod2 = YieldImg;
            }       


            updatedDrivingDataList = [
              {
                ...updatedDrivingDataList, 
                periodSummaryDrivingScore: periodSummaryDrivingScore,
                period1ResultCalculatedDrivingScore:period1ResultCalculatedDrivingScore,              
                period1ResultTravelDuration: period1ResultTravelDuration,
                period1ResultTravelDistance: period1ResultTravelDistance,
                period1ResultTotalRides: period1ResultTotalRides,
                period2ResultCalculatedDrivingScore:
                  period2ResultCalculatedDrivingScore,               
                period2ResultTravelDuration: period2ResultTravelDuration,
                period2ResultTravelDistance: period2ResultTravelDistance,
                period2ResultTotalRides: period2ResultTotalRides,
                periodSummarySpeedingViolation: periodSummarySpeedingViolation,              
                periodSummaryMobileViolation: periodSummaryMobileViolation,         
                accBrakCorViolation: accBrakCorViolation,          
                periodSummaryDrivingRedAlert: periodSummaryDrivingRedAlert,             
                alertImagesPeriodOne: alertImagesPeriod1,
                alertImagesPeriodTwo: alertImagesPeriod2,           
                badRidesCountSummary:badRidesCountSummary
                // textDataValueNew:textDataValue
              },
            ];
          });
         

          let checkValueIsZeroOne = updatedDrivingDataList?.every(
            (value) =>
              value?.period1ResultTotalRides == 0 ||
              value?.period1ResultTotalRides == ""
          );

          let checkValueIsZeroTwo = updatedDrivingDataList?.every(
            (value) =>
              value?.period2ResultTotalRides == 0 ||
              value?.period2ResultTotalRides == ""
          );         
          
          if (checkValueIsZeroOne && checkValueIsZeroTwo) {
            setSummaryText(
              "Both months have no rides; comparison not possible."
            );
            setDrivingDataList(defaultValueList);
          } else if (checkValueIsZeroOne) {
            setSummaryText("Month 1 has no rides; comparison not possible.");
            setDrivingDataList(updatedDrivingDataList);          
          } else if (checkValueIsZeroTwo) {            
            setSummaryText("Month 2 has no rides; comparison not possible.");
            setDrivingDataList(updatedDrivingDataList);
         
          } else {
            setSummaryText("");
            setDrivingDataList(updatedDrivingDataList);
          }
         
        } else {
          console.log("Status failed");
        }

      } else if (periodOneValue.format("YYYY-MM") === periodTwoValue.format("YYYY-MM")) {
       
        setSummaryText("Period One and Period Two cannot be the same");
        setDrivingDataList(defaultValueList);

      } else if (!periodOneValue.isBefore(periodTwoValue)) {
       
        setSummaryText("Month 2 should be greater than Month 1");
        setDrivingDataList(defaultValueList);

      } else if (

        periodOneValue.format("YYYY-MM") === checkTodayMonth.format("YYYY-MM") ||
        periodTwoValue.format("YYYY-MM") === checkTodayMonth.format("YYYY-MM")
     
      ) {
       
        setSummaryText("Please select a period before the current month");
        setDrivingDataList(defaultValueList);

      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
    
  };

  // 2
  const getRideAlertData = async () => {
    try {
      var result = await UserDashboardCtrlr.getV1RideDataSummary(
        filters.durationFilter,
        filters.startDateTime,
        filters.endDateTime
      );
      console.log("getRideAlerts", result);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON?.data;
        console.log("getRideAlertsddddd", resultJSONData);
      
        if (resultJSONData.length > 0) {        
          
          const rideSummary = resultJSONData[0].deviceDataSummary;
          const {
           
            animalCrossingCount,
            cautionCount,           
            curveCount,
            hillCount,
            hillDownwardsCount,
            hillUpwardsCount,
            icyConditionsCount,
            intersectionCount,
            laneMergeCount,
            lowGearAreaCount,           
            narrowRoadCount,            
            noOvertakingCount,           
            pedestrianCrossingCount,
            priorityCount,           
            railwayCrossingCount,
            riskOfGroundingCount,
            roundAboutCount,
            schoolZoneCount,            
            slipperyRoadsCount,
            stopSignCount,
            trafficLightCount,            
            windCount,
            windingRoadCount,
            yieldCount,
          } = rideSummary;
  
          const updatedData = data.map((item) => {
            switch (item.type) {
              case "ANIMAL_CROSSING":
                return { ...item, overallData: animalCrossingCount };
              case "CAUTION":
                return { ...item, overallData: cautionCount };
              case "CURVE":
                return { ...item, overallData: curveCount };
              case "ROUND":
                return { ...item, overallData: roundAboutCount };
              case "HILL":
                return { ...item, overallData: hillCount };
              case "HILL_DOWN":
                return { ...item, overallData: hillDownwardsCount };
              case "HILL_UP":
                return { ...item, overallData: hillUpwardsCount };
              case "ICY":
                return { ...item, overallData: icyConditionsCount };
              case "INTERSECTION":
                return { ...item, overallData: intersectionCount };
              case "LANE_MERGE":
                return { ...item, overallData: laneMergeCount };
              case "LOW_GEAR":
                return { ...item, overallData: lowGearAreaCount };
              case "NARROW_ROAD":
                return { ...item, overallData: narrowRoadCount };
              case "OVERTAKING":
                return { ...item, overallData: noOvertakingCount };
              case "CROSSING":
                return { ...item, overallData: pedestrianCrossingCount };
              case "PRIORITY":
                return { ...item, overallData: priorityCount };
              case "RAILWAY":
                return { ...item, overallData: railwayCrossingCount };
              case "RISK_GROUNDING":
                return { ...item, overallData: riskOfGroundingCount };
              case "SCHOOL":
                return { ...item, overallData: schoolZoneCount };
              case "SLIPPERY_ROADS":
                return { ...item, overallData: slipperyRoadsCount };
              case "STOP":
                return { ...item, overallData: stopSignCount };
              case "TRAFFIC_LIGHT":
                return { ...item, overallData: trafficLightCount };
              case "WIND":
                return { ...item, overallData: windCount };
              case "WINDING_ROAD":
                return { ...item, overallData: windingRoadCount };
              case "YIELD":
                return { ...item, overallData: yieldCount };
              default:
                return item;
            }
          });
  
          
          setRiskDataList(splitArrayIntoChunks(updatedData) || []);
        } else {
          console.log("Data not available");
        }
      } else {
        console.log("Status failed");
      }
    } catch (e) {
      console.log("Catch error: ", e);
    }
  };

  const [summaryData, setSummaryData] = useState([]);
  const [summaryDataUser, setSummaryDataUser] = useState(summaryData);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };

  useEffect(() => {   
    getRideAlertData();   
  }, [
    filters.durationFilter,
    indexStart,
    filters.startDateTime,
    filters.endDateTime,
  ]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const containerIds = ["container1"];

  const handleChange = (filterType) => {
    setCount(0);
    setStartDateTime("");
    setEndDateTime("");
    setFilters({
      startDateTime: "",
      endDateTime: "",
      durationFilter: filterType,
    });
  }; 

  const handleDateChangeFrom = (date) => {
    if (dayjs(date).isValid()) {
      setPeriodOneValue(date); // Ensure the date is valid and set it
    } else {
      console.error("Invalid date selected:", date);
    }
    // setPeriodOneValue(date)
  };

  const handleDateChangeTo = (date) => {
    if (dayjs(date).isValid()) {
      setPeriodTwoValue(date); // Ensure the date is valid and set it
    } else {
      console.error("Invalid date selected:", date);
    }
    // setPeriodTwoValue(date)
  };

  const handleDateFilterFunc = () => {
    if (periodOneValue && periodTwoValue) {    
      getDrivingComparisionData();
    } else {     
      ToastService.errormsg("Please select the date");
    }
  };

  useEffect(() => {    
    getDrivingComparisionData();    
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
       
        <Container fluid id="division">
          <div id="container1">
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            {showLoader > 0 && <Loader />}
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                className="shadow-sm "
                style={{ position: "fixed", zIndex: 1, background: "#fff" }}
              >
                <Row>
                  <Col xl={5} className="py-2">
                    {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                      <UserAdminDashboardSubMenu />
                    ) : (
                      <UserDashboardSubMenu />
                    )}
                  </Col>
                  <Col
                    xl={7}
                    className="py-2"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      

                      {(loginType == "ADMIN" ||
                        loginType == "SUPER_ADMIN" ||
                        loginType == "SUPER_ADMIN_AMAZON") && (
                        <div className="border-end border-secondary pe-2">
                          <Link to={"/myRides"}>
                            <button                             
                              className="btn btn-sm"
                              style={{
                                backgroundColor: "#FFCF23",
                                fontWeight: 700,
                              }}
                            >
                              Go To Rides
                              
                            </button>
                          </Link>
                        </div>
                      )}

                      <div className="d-flex justify-content-end">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
                          <DropdownToggle
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={DownloadIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                              }}
                            />
                          </DropdownToggle>

                          <DropdownMenu>                            
                            <DropdownItem
                              onClick={() => {
                                ExportScreenDownloadPdf.generatePDF(
                                  containerIds,
                                  loginType == "ADMIN" ||
                                    loginType == "SUPER_ADMIN"
                                    ? userFirstName + "'s" + " Overall Summary"
                                    : userName + "'s" + " Overall Summary"
                                );
                              }}
                            >
                              Export Screen
                            </DropdownItem>
                          
                            <DropdownItem
                              onClick={() => {
                                ViewPdf({
                                 
                                  overallDataProps,                                                        
                                  // USRideBoxCount,                            
                                  // aggregateData,
                                  totalDays,
                                  filters,
                                  summaryDataUser,                                 
                                 
                                });
                              }}
                            >
                              Download PDF
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                     
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="adminmenu-mt">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Month Wise Trends
                </div>
                <div style={{ display: "flex", float: "inline-end" }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        fontSize: "12px",
                        marginBottom: "0px",
                        marginTop: "5px",
                      }}
                    >
                      Month 1 &nbsp;
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                         
                          value={periodOneValue}
                          maxDate={lastMonthEndPeriodOne}
                          minDate={dayjs("2021-01-01")}
                          onChange={handleDateChangeFrom}
                          sx={{
                            "& .MuiInputBase-input": {
                              width: "50px",
                              fontSize: "12px",
                            },
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                "& .MuiInputBase-input": {
                                  padding: "12px 0px 7.5px 12px",
                                  fontSize: "12px", // Adjust padding as needed
                                },
                              },
                              inputProps: {
                                placeholder: "YYYY-MM", // Set the custom placeholder here
                              },
                            },
                          }}
                          // label={'"year" and "month"'}
                          format="YYYY-MMM"
                          views={["year", "month"]}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div style={{ display: "flex", marginLeft: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      Month 2 &nbsp;
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          // className="custom-mobile-date-picker"
                          value={periodTwoValue}
                          onChange={handleDateChangeTo}
                          maxDate={lastMonthEnd}
                          minDate={dayjs("2021-01-01")}
                          sx={{
                            "& .MuiInputBase-input": {
                              width: "50px",
                              fontSize: "12px",
                            },
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                "& .MuiInputBase-input": {
                                  padding: "12px 0px 7.5px 12px",
                                  fontSize: "12px", // Adjust padding as needed
                                },
                              },
                              inputProps: {
                                placeholder: "YYYY-MM", // Set the custom placeholder here
                              },
                            },
                          }}
                          // label={'"year" and "month"'}
                          format="YYYY-MMM"
                          views={["year", "month"]}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div>
                    <button
                      onClick={handleDateFilterFunc}
                      style={{
                        marginLeft: "5px",
                        marginTop: "9px",
                        fontWeight: 700,
                      }}
                      className="btn btnColorStylesNew me-3"
                      type="button"
                      //   onClick={searchSubmit}
                    >
                      Compare
                    </button>
                  </div>
                </div>
              </div>
            </Row>
           
            {drivingDataList.map((comparisonData, index) => (
            <Row key={index} style={{ marginTop: "15px" }}>
              <Col xl={4}>
              <Card style={{height:162}}>
                    <CardHeader
                      style={{
                        padding: "10px 10px",
                      }}
                      className="sub_heading cardHeader"
                    >
                      Driving Score
                    </CardHeader>
                    <CardBody style={{ padding: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // backgroundColor:"red"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "38%",
                            justifyContent:"center",
                            marginLeft:
                              comparisonData?.periodSummaryDrivingScore == 0
                                ? "0px"
                                : "0px",
                                // backgroundColor:"red"
                          }}
                        >
                          <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <p
                              style={{
                                // marginTop:"0px",
                                marginBottom: "0px",
                                fontSize: "35px",
                                display: "flex",
                                color:
                                  comparisonData?.periodSummaryDrivingScore > 0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryDrivingScore ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                                fontWeight: 700,
                              }}
                            >
                              {comparisonData?.periodSummaryDrivingScore > 0 ? (
                                <img
                                  src={upArroeIcon}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "9px" }}
                                />
                              ) : comparisonData?.periodSummaryDrivingScore ==
                                0 ? (
                                ""
                              ) : (
                                <img
                                  src={downArroeIcon}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "9px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.periodSummaryDrivingScore == " " ? (
                                "-"
                              ) : comparisonData?.periodSummaryDrivingScore == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.periodSummaryDrivingScore)}%`
                              )}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "10px",
                                marginBottom:"0px",
                                marginTop: "0px",
                                color:
                                  comparisonData?.periodSummaryDrivingScore > 0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryDrivingScore ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.periodSummaryDrivingScore > 0
                                ? "(Improved)"
                                : comparisonData?.periodSummaryDrivingScore == " "
                                ? ""
                                : comparisonData?.periodSummaryDrivingScore == 0
                                ? "(Neutral)"
                                : "(Not Improved)"}
                            </p>
                          </div>
                        </div>
                        <div style={{ width: "100%",display:"flex",justifyContent:"space-around"}}>
                          <div>
                            
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <div style={{ height: 60, width: 60 }}>
                                <Fab
                                  data-tooltip-id="period1ResultCalculatedDrivingScore"
                                  sx={{
                                    backgroundColor: "#009CDE",
                                    color: "black",
                                    zIndex: 0,
                                    boxShadow: "none",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    "&:hover": {
                                      backgroundColor: "#009CDE",
                                      cursor: "default",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  {comparisonData?.period1ResultCalculatedDrivingScore}
                                </Fab>
                                
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  marginLeft: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {comparisonData?.period1ResultTotalRides}
                                    {" Rides"}
                                  </span>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {
                                      comparisonData?.period1ResultTravelDuration
                                    }
                                    {" Mins"}
                                  </span>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {
                                      comparisonData?.period1ResultTravelDistance
                                    }
                                    {" Kms"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 400,
                                color: "#333333",
                                 textAlign:"center",
                                 display:"flex",
                                 justifyContent:"center"
                              }}
                            >
                              {periodOneValue
                                ? periodOneValue.format("MMMM")
                                : "No Month Selected"}
                            </span>
                          </div>

                          <div>
                            
                            <div style={{ display: "flex", marginTop: "10px" }}>
                              <div style={{ height: 60, width: 60 }}>
                                <Fab
                                  data-tooltip-id="period2ResultCalculatedDrivingScore"
                                  sx={{
                                    backgroundColor: "#009CDE",
                                    color: "black",
                                    zIndex: 0,
                                    boxShadow: "none",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    "&:hover": {
                                      backgroundColor: "#009CDE",
                                      cursor: "default",
                                      boxShadow: "none",
                                    },
                                  }}
                                >
                                  {comparisonData?.period2ResultCalculatedDrivingScore}
                                </Fab>
                                
                               
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  marginLeft: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {comparisonData?.period2ResultTotalRides}
                                    {" Rides"}
                                  </span>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {
                                      comparisonData?.period2ResultTravelDuration
                                    }
                                    {" Mins"}
                                  </span>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 400,
                                      color: "#333333",
                                    }}
                                  >
                                    {
                                      comparisonData?.period2ResultTravelDistance
                                    }
                                    {" Kms"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 400,
                                color: "#333333",
                                textAlign:"center",
                                display:"flex",
                                 justifyContent:"center"
                              }}
                            >
                              {periodTwoValue
                                ? periodTwoValue.format("MMMM")
                                : "No Month Selected"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p
                          style={{
                            fontSize: "10px",
                            color: summaryText && "red",
                            marginBottom:"0px",
                            marginTop:"10px",
                            marginLeft:comparisonData?.periodSummaryDrivingScore == 0 ? "43px" : "20px"
                            // textAlign:'center'
                          }}
                        >
                          {summaryText}
                        </p>
                    </CardBody>
                  </Card>
              </Col>
              <Col xl={8}>
              <Card style={{height:162}}>
                    <CardHeader
                      style={{
                        padding: "10px 10px",
                      }}
                      className="sub_heading cardHeader"
                    >
                      Other Informations
                    </CardHeader>
                    <CardBody style={{ padding: "0px",marginBottom:"0px" }}>
                      <Row style={{ marginBottom: "0px",}}>
                        <Col xs={2}>
                        <div
                            style={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: "0px",
                              // backgroundColor:"red",
                              marginTop:"7px"
                              
                               
                            }}
                          >
                            <p style={{fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px",display:"flex",justifyContent:"center"}}>Speeding Violation</p>
                            <p
                              style={{
                                marginBottom: "0px",
                                fontSize: "25px",
                                display: "flex",
                                marginTop:"15px",
                                justifyContent:"center",
                                color:
                                  comparisonData?.periodSummarySpeedingViolation >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummarySpeedingViolation ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                                fontWeight: 700,
                              }}
                            >
                              {comparisonData?.periodSummarySpeedingViolation >
                              0 ? (
                                <img
                                  src={downArrowIconNew}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              ) : comparisonData?.periodSummarySpeedingViolation ==
                                0 ? (
                                ""
                              ) : (
                                <img
                                  src={upArrowIconRed}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.periodSummarySpeedingViolation == " " ? (
                                "-"
                              ) : comparisonData?.periodSummarySpeedingViolation == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.periodSummarySpeedingViolation)}%`
                              )}
                            </p>
                            <p
                              style={{
                                fontWeight: 500,
                                fontSize: "10px",
                                marginTop: "10px",
                                marginBottom:"0px",
                                color:
                                  comparisonData?.periodSummarySpeedingViolation >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummarySpeedingViolation ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.periodSummarySpeedingViolation >
                              0
                                ? "(Decreased)"
                                : comparisonData?.periodSummarySpeedingViolation == " "
                                ? " "
                                : comparisonData?.periodSummarySpeedingViolation == 0
                                ? "(Neutral)"
                                : "(Increased)"}
                            </p>
                          </div>
                        </Col>
                        <Col xs={2}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                               marginBottom: "0px",
                              //  backgroundColor:"red",
                               marginTop:"7px"
                            }}
                          >
                            <p style={{display:"flex",justifyContent:"center",whiteSpace:"nowrap",fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px"}}>Mobile Violation</p>
                            <p
                              style={{
                                marginTop:"15px",
                                fontSize: "25px",
                                display: "flex",
                                marginBottom:"0px",
                                justifyContent:"center",
                                color:
                                  comparisonData?.periodSummaryMobileViolation >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryMobileViolation ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                                fontWeight: 700,
                              }}
                            >
                              {comparisonData?.periodSummaryMobileViolation >
                              0 ? (
                                <img
                                  src={downArrowIconNew}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              ) : comparisonData?.periodSummaryMobileViolation ==
                                0 ? (
                                ""
                              ) : (
                                <img
                                  src={upArrowIconRed}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.periodSummaryMobileViolation == " " ? (
                                "-"
                              ) : comparisonData?.periodSummaryMobileViolation == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.periodSummaryMobileViolation)}%`
                              )}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "10px",
                                marginTop: "10px",
                                marginBottom:"0px",
                                display:"flex",justifyContent:"center",
                                color:
                                  comparisonData?.periodSummaryMobileViolation >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryMobileViolation ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.periodSummaryMobileViolation > 0
                                ? "(Decreased)"
                                : comparisonData?.periodSummaryMobileViolation == " "
                                ? " "
                                : comparisonData?.periodSummaryMobileViolation == 0
                                ? "(Neutral)"
                                : "(Increased)"}
                            </p>
                          </div>
                        
                        </Col>
                        <Col xs={2}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: "0px",
                              marginTop:"7px"
                            }}
                          >
                             <p style={{display:"flex",justifyContent:"center",fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px"}}>Acc/Brak/Cor Violation</p>
                            <p
                              style={{
                                marginBottom: "0px",
                                marginTop:"15px",
                                fontSize: "25px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent:"center",
                                color:
                                  comparisonData?.accBrakCorViolation > 0
                                    ? "#24B200"
                                    : comparisonData?.accBrakCorViolation == 0
                                    ? "black"
                                    : "#EB0000",
                                fontWeight: 700,
                              }}
                            >
                              {comparisonData?.accBrakCorViolation > 0 ? (
                                <img
                                  src={downArrowIconNew}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              ) : comparisonData?.accBrakCorViolation == 0 ? (
                                ""
                              ) : (
                                <img
                                  src={upArrowIconRed}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.accBrakCorViolation == " " ? (
                                "-"
                              ) : comparisonData?.accBrakCorViolation == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.accBrakCorViolation)}%`
                              )}
                            </p>
                            <p
                              style={{
                                fontWeight: 500,
                                textAlign: "center",
                                fontSize: "10px",
                                marginTop: "10px",
                                marginBottom: "0px",
                                display:"flex",justifyContent:"center",
                                color:
                                  comparisonData?.accBrakCorViolation > 0
                                    ? "#24B200"
                                    : comparisonData?.accBrakCorViolation == 0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.accBrakCorViolation > 0
                                ? "(Decreased)"
                                : comparisonData?.accBrakCorViolation == " "
                                ? " "
                                : comparisonData?.accBrakCorViolation == 0
                                ? "(Neutral)"
                                : "(Increased)"}
                            </p>
                          </div>
                          
                        </Col>
                        <Col xs={2}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                               marginBottom: "0px",
                               marginTop:"7px"
                            }}
                          >
                             <p style={{display:"flex",justifyContent:"center",fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px"}}>Bad Rides Count</p>
                            <p
                              style={{
                                marginBottom: "0px",
                                marginTop:"15px",
                                fontSize: "25px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent:"center",
                                color:"black",
                                fontWeight: 700,
                                color:
                                  comparisonData?.badRidesCountSummary >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.badRidesCountSummary ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.badRidesCountSummary > 0 ? (
                                <img
                                  src={downArrowIconNew}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              ) : comparisonData?.badRidesCountSummary == 0 ? (
                                ""
                              ) : (
                                <img
                                  src={upArrowIconRed}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.badRidesCountSummary == " " ? (
                                "-"
                              ) : comparisonData?.badRidesCountSummary == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.badRidesCountSummary)}%`
                              )}
                            </p>
                            <p
                              style={{
                                fontWeight: 500,
                                textAlign: "center",
                                fontSize: "10px",
                                marginTop: "10px",
                                marginBottom: "0px",
                                display:"flex",justifyContent:"center",                                
                                color:
                                  comparisonData?.badRidesCountSummary > 0
                                    ? "#24B200"
                                    : comparisonData?.badRidesCountSummary == 0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            > 
                              {comparisonData?.badRidesCountSummary > 0
                                ? "(Decreased)"
                                : comparisonData?.badRidesCountSummary == " "
                                ? " "
                                : comparisonData?.badRidesCountSummary == 0
                                ? "(Neutral)"
                                : "(Increased)"}
                            </p>
                          </div>
                         
                        </Col>
                        <Col xs={2}>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginBottom: "0px",
                              marginTop:"7px"
                            }}
                          >
                            <p style={{display:"flex",justifyContent:"center",fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px"}}>Red Alert Counts</p>
                            <p
                              style={{
                                marginBottom: "0px",
                                marginTop:"15px",
                                fontSize: "25px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent:"center",
                                color:
                                  comparisonData?.periodSummaryDrivingRedAlert >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryDrivingRedAlert ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                                fontWeight: 700,
                              }}
                            >
                              {comparisonData?.periodSummaryDrivingRedAlert >
                              0 ? (
                                <img
                                  src={downArrowIconNew}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              ) : comparisonData?.periodSummaryDrivingRedAlert ==
                                0 ? (
                                ""
                              ) : (
                                <img
                                  src={upArrowIconRed}
                                  alt="downArrow"
                                  style={{ height: "35px", marginTop: "1px" }}
                                />
                              )}
                              &nbsp;
                              {comparisonData?.periodSummaryDrivingRedAlert == " " ? (
                                "-"
                              ) : comparisonData?.periodSummaryDrivingRedAlert == 0 ? (
                                "0"
                              ) : (
                                `${Math.abs(comparisonData?.periodSummaryDrivingRedAlert)}%`
                              )}
                            </p>
                            <p
                              style={{
                                fontWeight: 500,
                                textAlign: "center",
                                fontSize: "10px",
                                marginTop: "10px",
                                marginBottom: "0px",
                                display:"flex",justifyContent:"center",
                                color:
                                  comparisonData?.periodSummaryDrivingRedAlert >
                                  0
                                    ? "#24B200"
                                    : comparisonData?.periodSummaryDrivingRedAlert ==
                                      0
                                    ? "black"
                                    : "#EB0000",
                              }}
                            >
                              {comparisonData?.periodSummaryDrivingRedAlert > 0
                                ? "(Decreased)"
                                : comparisonData?.periodSummaryDrivingRedAlert == " "
                                ? " "
                                : comparisonData?.periodSummaryDrivingRedAlert == 0
                                ? "(Neutral)"
                                : "(Increased)"}
                            </p>
                          </div>
                        </Col>
                        <Col xs={2}>
                        <div style={{ marginBottom: "0px",marginTop:"10px"}} >
                        <p style={{fontSize:"10px",color:"#333333",fontWeight:500,marginBottom:"0px",marginTop:"5px",display:"flex",justifyContent:"center",}}>Frequent Red Alert</p>
                         <div style={{marginTop:"5px",display:"flex",justifyContent:"space-around", marginBottom: "0px",}}>
                          <div>                           
                           
                            <div style={{marginBottom:"3px"}}>
                              <img
                                style={{ height: 50, width: 50 }}
                                src={comparisonData?.alertImagesPeriodOne}
                              />
                              
                            </div>

                            <span
                              style={{
                                fontSize: "10px",
                                fontWeight: 500,
                                color: "#000000",
                                
                              }}
                            >
                              {periodOneValue
                                ? periodOneValue.format("MMMM")
                                : "No Month Selected"}
                            </span>
                          </div>

                          <div>
                            
                           
                            <div style={{marginBottom:"3px"}}>
                              <img
                                style={{ height: 50, width: 50 }}
                                src={comparisonData?.alertImagesPeriodTwo}
                              />                             
                            </div>
                            <span
                              style={{
                                fontSize: "10px",
                                fontWeight: 500,
                                color:+ "#000000",
                              }}
                            >
                              {periodTwoValue
                                ? periodTwoValue.format("MMMM")
                                : "No Month Selected"}
                            </span>
                          </div>
                          </div>
                        </div>
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
              </Col>
            </Row>))}
           
            <Row style={{ marginTop: "-8px" }}>
              
              <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontSize: "16px",
                  fontWeight: 500,
                 
                }}
              >
                Summary
              </div>
                      <div className="ms-auto">
                        <div style={{ float: "right" }}>
                          
                          <button
                            type="button"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_DAY" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => handleChange("CURRENT_DAY")}
                            data-tooltip-id="cd"
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Showing zone summary for current day"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                          <button
                            type="button"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_WEEK" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_WEEK")}
                            data-tooltip-id="cw"
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Showing zone summary for current week"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_MONTH" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_MONTH")}
                            data-tooltip-id="cm"
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Showing zone summary for current month"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_YEAR" &&
                              !modalIsOpen
                                ? "btn btnColorStyles btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => handleChange("CURRENT_YEAR")}
                            data-tooltip-id="cy"
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Showing zone summary for current year"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CUSTOM_DURATION" ||
                              modalIsOpen
                                ? "btn btnColorStyles btn-sm me-2"
                                : "btn btn-soft-secondary btn-sm me-2"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="custom duration"
                          >
                            {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                              <img
                                src={filterClockIcon}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            ) : (
                              <img
                                src={filterClockIconDe}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            )}
                            <ReactTooltip
                              id="custom duration"
                              place="bottom"
                              content="Showing zone summary for custom duration"
                              style={{ background: "black", zIndex: 100 }}
                            />
                          </button>
                        </div>
                      </div>               
                     

                     
                    </div>
              <DriverBehaviourRatingForUser
                receiveSummaryData={handleSummaryData}
                filter={filters}
              />
            </Row>
            {/* </div> */}

            <Row style={{ marginTop: "-10px" }}>
              <MarketOverview filter={filters} loaderCount={setShowLoader} />             
            </Row>

            <div id="container4" style={{ marginTop: "-5px" }}>
              <Row>
                <Col xl={12} lg={12}>
                  <Card>
                    <CardHeader
                      className="cardHeader"
                      style={{ padding: "5px" }}
                    >
                      <h4 className="card-title">
                        Risk Alerts{" "}
                        <span style={{ fontWeight: 400, fontSize: "12px" }}>
                          {/* (Total Alerts - {totalAlerts}) */}
                        </span>
                      </h4>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>

              <Row>
                
                {(riskDataList || []).map((widgets, key) => (
                  <Col xl={3} md={4} sm={6} xs={12} key={key}>
                    <Row style={{ marginBottom: "-11px" }}>
                      {widgets.map((widget) => (
                        <Col xl={6} md={6} sm={6} xs={12} key={key}>
                          <Card
                            className="card-h-100"
                            style={{ width: "100%" }}
                          >
                            <CardBody style={{ padding: "5px" }}>
                              <Row className="align-items-center">
                                <Col xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      className="text-nowrap"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={widget.image}
                                        alt="cardOneImg"
                                        height="25px"
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        // backgroundColor:"red",
                                        width: "70%",
                                      }}
                                    >
                                      <span
                                        // className="text-muted mb-3 d-block text-truncate"
                                        style={{
                                          fontSize: "10px",
                                          textAlign: "left",
                                        }}
                                      >
                                        {widget.title}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {/* <h4> */}

                                      <span
                                        className="counter-value"
                                        style={{
                                          textAlign: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CountUp
                                          start={0}
                                          end={widget.overallData}
                                          duration={1}
                                        />
                                      </span>

                                      {/* </h4> */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
            
          </div>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setFilter={setFilter}
            filter={filters.durationFilter}
          />
          {/* </div> */}
        </Container>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default UserDashboard;
