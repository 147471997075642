import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import {
  AdminDashboardRideBoxCount,
  AdminDashboardTopRowItems,
} from "../../../common/data/admin/dashboard.js";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ValueFormat from "../../../util/ValueFormat.js";
import OverallSummary from "../OverallSummary/overallSummary.js";
import DrivingBehaviour from "../Charts/multiuserDrivingBehaviour.js";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SC_SELECTED_USER_VALUE,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";
import RidesKmHrsScoreCBar from "./RidesKmHrsScoreCBar.js";
import "./Submenu.css";
import filterClockIcon from "../../../assets/images/common/filterClockIcon.svg";
import filterClockIconDe from "../../../assets/images/common/filterIconClockDeactive.svg";
import { FormGroup, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AdminDashboardCtrlr from "../../../controller/AdminDashboardCtrlr.js";


const OverallSummaryy = () => {

  let drivingCategoryList = [{
    drivingSummaryTotalRides:0,
    badDrivingCount:0,
    dangerousDrivingCount:0,
    goodDrivingCount:0,
    quietSafeDrivingCount:0,
    noOfDays:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    goodDrivingPercentage:0,
    quitSafeDrivingPercentage:0,
    dangerousDrivingPercentage:0,
    badDrivingPercentage:0
}]

  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );
 
  const [showLoader, setShowLoader] = useState(0);  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState(""); 
  const selectedUserValue = localStorage.getItem(SC_SELECTED_USER_VALUE) || "";
  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_WEEK",
    startDateTime: "",
    endDateTime: "",
  });
  const [aggregateData, setAggregateData] = useState({
    noOfDays:0,
    drivingSummaryTotalRides:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    totalUsers:0,
  });
  const [totalDays, setTotalDays] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);  
  const [selectedValue, setSelectedValue] = useState("ACTIVE_USER_LIST");
  const [ drivingDataList, setDrivingDataList] = useState(drivingCategoryList);
  const [summaryData, setSummaryData] = useState([]);

  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);

  const handleChange = (event) => {
    setSelectedValue(event.target.value); 
  };

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const getTotalValues = async () => {

    setCount(1);

    const transportType = selectedUserValue;

    setShowLoader((current) => current + 1);

    var result = await AdminDashboardCtrlr.getV1AdminDashboardSummary(
      filters.startDateTime,
      filters.endDateTime,
      filters.durationFilter,
      transportType
    );

    setShowLoader((current) => current - 1);

    var resultJSON = JSON.parse(result);
    console.log(resultJSON,"AdminAdminn")
    var status = resultJSON.status; 

    if (status == "SUCCESS") {

      var resultJSONData = resultJSON.data;  
      console.log(resultJSONData,"resultJSONDatahhkjjh")        
      var safeRide = 0;
      var userCountPercentages = [];
      var getTheValueTopFore = [];
      // console.log("AdminTopRows:",(resultJSONData));
      let totalDays = 0;     
      
      resultJSONData.map((apiResultTemp) => {

        var tableTotalUserList = ValueFormat.parseAsObject(apiResultTemp.userSummary);
        
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.totalUserCount)
        );
        
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(tableTotalUserList.registeredUserCount)
        );

        var totalDeactivatedUsersCount = ValueFormat.parseAsInt(
          tableTotalUserList.deactivatedUserCount
        );

        totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.divisionAgeInDay));  
        
        let drivingSummary = ValueFormat.parseAsObject(apiResultTemp.drivingSummary);
        let drivingCategoryCount = ValueFormat.parseAsObject(apiResultTemp.drivingSummary.drivingCategoryDetail);
        const drivingSummaryTotalRides = ValueFormat.parseAsString(drivingSummary.totalRideCount);
        const totalUsers = ValueFormat.parseAsString(drivingSummary.totalUserCount);
        const badDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.badDrivingCount);
        const dangerousDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.dangerousDrivingCount);
        const goodDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.goodDrivingCount);
        const quietSafeDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.quietSafeDrivingCount);
        const noOfDays = ValueFormat.parseAsString(apiResultTemp.divisionAge);
        const totalDistance = ValueFormat.parseAsFloat(drivingSummary.totalDistanceInKm,2);       
        const totalDuration = ValueFormat.parseAsFloat(drivingSummary.totalDurationInMin,2);
        const totalHighRiskCountNew = ValueFormat.parseAsFloat(drivingSummary.totalHighAlertCount);
        const goodDrivingPercentage = ((goodDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const quitSafeDrivingPercentage = ((quietSafeDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const dangerousDrivingPercentage = ((dangerousDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const badDrivingPercentage = ((badDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
       
        setAggregateData({
          noOfDays,
          drivingSummaryTotalRides,
          totalDistance,
          totalDuration,
          totalHighRiskCountNew,
          totalUsers
        })      

        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);     
       
        var totalRegisteredUsersCount = activeUserCount + inactiveUserCount;
        
        let sendTheDataList = {
          drivingSummaryTotalRides:drivingSummaryTotalRides,
          badDrivingCount:badDrivingCount,
          dangerousDrivingCount:dangerousDrivingCount,
          goodDrivingCount:goodDrivingCount,
          quietSafeDrivingCount:quietSafeDrivingCount,
          noOfDays:noOfDays,
          totalDistance:totalDistance,
          totalDuration:totalDuration,
          totalHighRiskCountNew:totalHighRiskCountNew,
          goodDrivingPercentage:goodDrivingPercentage,
          quitSafeDrivingPercentage:quitSafeDrivingPercentage,
          dangerousDrivingPercentage:dangerousDrivingPercentage,
          badDrivingPercentage:badDrivingPercentage
        }

        getTheValueTopFore.push(sendTheDataList);

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );

        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
            } else if (staticObjectTemp.type === "DEACTIVATED") {
              isTypeMatched = true;
              totalCount = totalDeactivatedUsersCount;
            } else if (staticObjectTemp.type === "REGISTERED") {
              isTypeMatched = true;
              totalCount = totalRegisteredUsersCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }

          })
        );
        
      });
      
      setTotalDays(totalDays);
      setDrivingDataList(getTheValueTopFore);

    } else {
     
    }
  };

  const containerIds = ["container1"];

  useEffect(() => {    
    getTotalValues();
  }, [
    filters.durationFilter,    
    filters.startDateTime,
    filters.endDateTime,
  ]); 

  const handleSummaryData = (data) => {
    setSummaryData(data);
  }; 

  const handleDownloadPdf = () => {
    ViewPdf({
      ADTopRowItemsTemp,
      drivingDataList,
    
      summaryData,
      // riskSummaryData,
      // aggregateData1,
      // totalDays1,
      // filtersADOS,
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
       
        <Container fluid id="division">
          <div id="container1" style={{ margin: "0px", padding: "0px" }}>
          
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{
                  position: "fixed",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
                className="shadow-sm"
              >
                <Row>
                  <Col xl={7} className="py-2">
                    <div style={{ display: "flex" }}>
                      <AdminDashboardSubMenu />
                    </div>
                  </Col>

                  <Col
                    className="py-2"
                    xl={5}
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_DAY" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              });
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_WEEK" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              });
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_MONTH" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              });
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_YEAR" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              });
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CUSTOM_DURATION" ||
                              modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                              <img
                                src={filterClockIcon}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            ) : (
                              <img
                                src={filterClockIconDe}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            )}
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>

                      {/* <div className="d-flex justify-content-end">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
                          <DropdownToggle
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={DownloadIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                              }}
                            />
                          </DropdownToggle>

                          <DropdownMenu>
                            
                            <DropdownItem
                              onClick={() => {
                                ExportScreenDownloadPdf.generatePDF(
                                  containerIds,
                                  userFirstName + "'s" + " Overall Summary"
                                );
                              }}
                            >
                              Export Screen
                            </DropdownItem>

                           
                          </DropdownMenu>
                        </Dropdown>
                      </div> */}

                      {/* <ExportScreenPdf
                        containerIds={containerIds}
                        fileName={userFirstName + "'s" + " Overall Summary"}
                      /> */}
                      <ViewPdf
                        ADTopRowItemsTemp={ADTopRowItemsTemp}
                        drivingDataList={drivingDataList}                       
                        summaryData={summaryData}
                        // riskSummaryData={riskSummaryData}
                        aggregateData1={aggregateData}
                        totalDays1={totalDays}
                        filtersADOS={filters}
                        selectedValue={selectedValue}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col  xl={3} lg={6} md={6}>
                <Card>
                  <CardBody style={{}} className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr key={index} className="">
                              <td
                                style={{
                                  padding: "4.7px 20px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    verticalAlign: "middle",
                                    marginBottom: "0px",
                                    marginTop: "0px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                      marginLeft:
                                        index == 2 || index == 3
                                          ? "30px"
                                          : index == 1 ||
                                            index == 4 ||
                                            index == 5
                                          ? "15px"
                                          : "0px",
                                    }}
                                  >
                                    {item.title}
                                  </p>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={selectedValue} // Controlled by state
                                    onChange={handleChange} // Handle changes
                                  >
                                    <FormControlLabel
                                      sx={{
                                        padding: "0px",
                                        display: index == 1 ? "none" : "block",
                                        marginBottom: "0px",
                                      }}
                                      value={item.filterKeyValue}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{ padding: "0px" }}
                                        />
                                      }
                                    />
                                  </RadioGroup>
                                 
                                </div>
                              </td>

                              <td
                                style={{
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 700 }}
                                >
                                  {item.totalCount}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={9} lg={6} md={6}>
              {drivingDataList.map((val,i) => (
                <Row key={i}>
                  <Card style={{height:110}}>
                    <CardBody
                      className="body_text"
                      style={{ padding: "10px", height: "100px" }}
                    >
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.goodDrivingPercentage}
                              text={`${val.goodDrivingPercentage == "NaN" ? "0" : val.goodDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                           Good Driving
                            <br />
                            <span>
                              {val.goodDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                       

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                           
                            <CircularProgressbar
                              value={val.quitSafeDrivingPercentage}
                              text={`${val.quitSafeDrivingPercentage == "NaN" ? "0" : val.quitSafeDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#FFBF00",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              fontWeight: 700,
                            }}
                          >
                           Quite Safe Driving
                            <br />
                            <span>
                              {val.quietSafeDrivingCount +
                                " / " +
                                val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                      

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.dangerousDrivingPercentage}
                              text={`${val.dangerousDrivingPercentage == "NaN" ? "0" : val.dangerousDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Dangerous Driving
                            <br />
                            <span>
                              {val.dangerousDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>                            
                            <CircularProgressbar
                              value={val.badDrivingPercentage}
                              text={`${val.badDrivingPercentage == "NaN" ? "0" : val.badDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            Bad Driving
                            <br />
                            <span>
                              {val.badDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                ))}
                
                <Row style={{marginTop:"-11px"}}>

                <RidesKmHrsScoreCBar
                    aggregateData={aggregateData}
                    totalDays={totalDays}
                  />

                </Row>
                  
                
              </Col>
              
              </Row>

          <OverallSummary
            filter={filters}
            receiveSummaryData={handleSummaryData}
            selectedUserValue={selectedUserValue}
            selectedValue={selectedValue}
          />

            <DrivingBehaviour
              loaderCount={setShowLoader}
              filter={filters}
              selectedUserValue={selectedUserValue}
            />
         
          </div>

          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setFilter={setFilter}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            filter={filters.durationFilter}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OverallSummaryy;
